import { css, SerializedStyles } from '@emotion/react';
import { getBreakpoint } from './functions';
import { COLOR } from '@/app/common/style/variables';

export function clearfix(): SerializedStyles {
  return css`
    &:before,
    &:after {
      content: '';
      display: table;
    }
    &:after {
      clear: both;
    }
  `;
}

export function mobile(content: SerializedStyles): SerializedStyles {
  return css`
    @media (max-width: calc(${getBreakpoint(`tablet`)} - 1px)) {
      ${content};
    }
  `;
}

export function tablet(content: SerializedStyles): SerializedStyles {
  return css`
    @media (min-width: ${getBreakpoint(
        `tablet`,
      )}) and (max-width: calc(${getBreakpoint(`desktop`)} - 1px)) {
      ${content};
    }
  `;
}

export function tabletAndUp(content: SerializedStyles): SerializedStyles {
  return css`
    @media (min-width: ${getBreakpoint(`tablet`)}) {
      ${content};
    }
  `;
}

export function tabletDown(content: SerializedStyles): SerializedStyles {
  return css`
    @media (min-width: 0) and (max-width: calc(${getBreakpoint(
        `tablet`,
      )} - 1px)) {
      ${content};
    }
  `;
}

export function desktop(content: SerializedStyles): SerializedStyles {
  return css`
    @media (min-width: ${getBreakpoint(
        `desktop`,
      )}) and (max-width: calc(${getBreakpoint(`large`)} - 1px)) {
      ${content};
    }
  `;
}

export function desktopDown(content: SerializedStyles): SerializedStyles {
  return css`
    @media (min-width: 0) and (max-width: calc(${getBreakpoint(
        `desktop`,
      )} - 1px)) {
      ${content};
    }
  `;
}

export function desktopLarge(content: SerializedStyles): SerializedStyles {
  return css`
    @media (min-width: ${getBreakpoint(
        `large`,
      )}) and (max-width: calc(${getBreakpoint(`wide`)} - 1px)) {
      ${content};
    }
  `;
}

export function desktopLargeDown(content: SerializedStyles): SerializedStyles {
  return css`
    @media (min-width: 0) and (max-width: calc(${getBreakpoint(
        `large`,
      )} - 1px)) {
      ${content};
    }
  `;
}

export function wide(content: SerializedStyles): SerializedStyles {
  return css`
    @media (min-width: ${getBreakpoint(`wide`)}) {
      ${content};
    }
  `;
}

export function wideAndBelow(content: SerializedStyles): SerializedStyles {
  return css`
    @media (max-width: calc(${getBreakpoint(`wide`)} - 1px)) {
      ${content};
    }
  `;
}

export function desktopLargeAndBelow(
  content: SerializedStyles,
): SerializedStyles {
  return css`
    @media (max-width: calc(${getBreakpoint(`large`)} - 1px)) {
      ${content};
    }
  `;
}

export function desktopAndBelow(content: SerializedStyles): SerializedStyles {
  return css`
    @media (max-width: calc(${getBreakpoint(`tablet`)} - 1px)) {
      ${content};
    }
  `;
}

export function desktopAndBelowActual(
  content: SerializedStyles,
): SerializedStyles {
  return css`
    @media (max-width: calc(${getBreakpoint(`desktop`)} - 1px)) {
      ${content};
    }
  `;
}

export function tabletAndBelow(content: SerializedStyles): SerializedStyles {
  return css`
    @media (max-width: calc(${getBreakpoint(`tablet`)} - 1px)) {
      ${content};
    }
  `;
}

export function phablet(content: SerializedStyles): SerializedStyles {
  return css`
    @media (min-width: 576px) and (max-width: 768px - 1px) ) {
      ${content};
    }
  `;
}

export function phabletAndBelow(content: SerializedStyles): SerializedStyles {
  return css`
    @media (max-width: calc(${getBreakpoint(`phablet`)} - 1px)) {
      ${content};
    }
  `;
}

export function mobileAndBelow(content: SerializedStyles): SerializedStyles {
  return css`
    @media (max-width: calc(425 - 1px)) {
      ${content};
    }
  `;
}

export function arrow(_rotate = `0deg`, _size = `14px`): SerializedStyles {
  return css`
    display: inline-block;
    background-image: url('/assets/images/arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: rotate(${_rotate});
    width: ${_size};
    height: ${_size};
  `;
}

export function twoColumnFlex(): SerializedStyles {
  return css`
    display: flex;
    > div {
      flex-basis: 50%;

      &:not(:last-child) {
        padding-right: 40px;
      }

      &:not(:first-of-type) {
        padding-left: 40px;
      }
    }

    ${desktopAndBelow(css`
      flex-direction: column;
      > div {
        &:not(:last-child) {
          padding: 0 0 40px 0;
        }

        &:not(:first-of-type) {
          padding: 40px 0 0 0;
        }
      }
    `)}
  `;
}

export function useContentColor(_color = COLOR.woodSmoke1): SerializedStyles {
  return css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
      color: _color;
    }
  `;
}
